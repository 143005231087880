<template>
    <div class="flip-clock-wrapper"
         ref="flipClock">
    </div>
</template>

<script>
import FlipClock from "flipclock";

export default {
    name: "FlipClock",
    props: {
        value: {
            type: [String, Number]
        },

        face: {
            type: String,
            default: "HourCounter",
            validator (value) {
                return [
                    "HourCounter",
                    "MinuteCounter",
                    "DayCounter",
                    "TwelveHourClock",
                    "TwentyFourHourClock",
                    "Counter"
                ].includes(value)
            }
        },

        autoStart: {
            type: Boolean,
            default: false,
        },

        minimumDigits: {
            type: Number,
            default: 6
        },
    },
    data () {
        return {
            instance: null,
        };
    },
    computed: {

    },
    methods: {

    },
    mounted () {
        if (parseInt(this.value)) {
            this.instance = new FlipClock(
                this.$refs.flipClock,
                this.value,
                {
                    face: this.face,
                    autoStart: this.autoStart,
                    minimumDigits: this.minimumDigits,
                }
            );
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
//$fc-face-font-size: 9em;
//$fc-face-width: 8em;
//$fc-face-height: 12em;

@import "~flipclock/src/scss/flipclock";

.flip-clock {
    font-size: 25px;
}
</style>
